import {
  action, makeObservable, observable,
} from "mobx";
import * as segmentsService from "../requests/segments/segments/segments";
import * as segmentAccessService from "../requests/segments/segmentAccess/segmentAccess";
import {
  ISegment, ISegmentAccess, IUserType,
} from "../types/ISegment";
import { ICampaign } from "../types/ICampaign";
import { IRootStore } from "../types/IRootStore";

const USER_TYPE: IUserType = "user";

export class SegmentsStore {
  rootStore;

  @observable isLoading = false;

  @observable currentSegment: ISegment | null = null;

  @observable defaultSegments: ISegment[] = [];

  @observable segments: ISegment[] = [];

  constructor(rootStore: IRootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.isLoading = false;
  }

  @action setCurrentSegment(currentSegment: ISegment | null) {
    this.currentSegment = currentSegment;
  }

  @action
  setDefaultSegments(defaultSegments: ISegment[]) {
    this.defaultSegments = defaultSegments;
  }

  @action
  setSegments(segments: ISegment[]) {
    this.segments = segments;
  }

  @action
  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @action
  async getSegmentsAsync(
    authToken: string,
    campaignId: ICampaign["id"],
  ) {
    this.setIsLoading(true);

    try {
      const segments = await segmentsService.getSegments(authToken, campaignId);
      this.setSegments(segments);
    } catch (error) {
      this.setSegments([]);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async createSegmentAsync(
    token: string,
    campaignId: ICampaign["id"],
    payload: Pick<ISegment, "name" | "terms">,
  ) {
    const segment = await segmentsService.createSegment(token, campaignId, payload);

    this.setSegments([...this.segments, segment]);
    this.setCurrentSegment(segment);

    return segment;
  }

  @action
  modifyCurrentSegment(updatedSegments: ISegment[]) {
    if (this.currentSegment) {
      const currentSegment = updatedSegments.find(
        (segment) => segment.id === this.currentSegment?.id,
      );

      if (currentSegment) {
        this.setCurrentSegment(currentSegment);
      }
    }
  }

  @action
  async updateSegmentAsync(
    token: string,
    campaignId: ICampaign["id"],
    segmentId: ISegment["id"],
    payload: Pick<ISegment, "name" | "terms">,
  ) {
    await segmentsService.updateSegment(token, campaignId, segmentId, payload);

    const updatedSegments = this.segments.map((segment) => {
      if (segment.id === segmentId) {
        return {
          ...segment,
          name: payload.name,
          terms: payload.terms,
        };
      }

      return segment;
    });

    this.setSegments(updatedSegments);
    this.setCurrentSegment(null);
  }

  @action
  async shareSegmentAsync(
    token: string,
    campaignId: ICampaign["id"],
    segmentId: ISegment["id"],
    userIds: string[],
  ) {
    const accessEntries: ISegmentAccess[] = userIds.map((userId) => ({
      id: Number(userId),
      type: USER_TYPE,
      creator: false,
    }));

    await segmentAccessService.updateSegmentAccess(token, campaignId, segmentId, accessEntries);

    const updatedSegments = this.segments.map((segment) => {
      if (segment.id === segmentId) {
        return {
          ...segment,
          accessibleBy: [
            ...segment.accessibleBy,
            ...accessEntries,
          ],
        };
      }

      return segment;
    });

    this.setSegments(updatedSegments);
    this.modifyCurrentSegment(updatedSegments);
  }

  @action
  async deleteSegmentAccessAsync(
    token: string,
    campaignId: ICampaign["id"],
    segmentId: ISegment["id"],
    userIds: string[],
  ) {
    const accessEntries: ISegmentAccess[] = userIds.map((userId) => ({
      id: Number(userId),
      type: USER_TYPE,
      creator: false,
    }));

    await segmentAccessService.deleteSegmentAccess(token, campaignId, segmentId, accessEntries);

    const updatedSegments = this.segments.map((segment) => {
      if (segment.id === segmentId) {
        const newAccessibleBy = segment.accessibleBy.filter(
          (accessibleBy) => !userIds.includes(accessibleBy.id.toString()),
        );

        return {
          ...segment,
          accessibleBy: newAccessibleBy,
        };
      }

      return segment;
    });

    this.setSegments(updatedSegments);
    this.modifyCurrentSegment(updatedSegments);
  }
}

export default SegmentsStore;
